.disabled-click{
 pointer-events: none;
}

.block-loading {
 position: fixed;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 z-index: 1010;
 display: table;
 height: 100%;
 width: 100%;

 &.overlay-black {
  background-color: #fff;
  opacity: 0.6;
  transition: 500ms;
 }

 .block-loading-inner {
  z-index: 1011;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  .loading-icon {
   z-index: 1012;
   height: 25px;
   width: 25px;
  }
 }
}

.disable-button{
 pointer-events: none;
 opacity: 0.4;
}

.text-underline {
 text-decoration: underline;
}
@media print {
  .avoid-break-page {
    page-break-inside: avoid;
  }
  .page-break {
    page-break-after: always;
  }
}