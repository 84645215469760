/* For use in src/lib/core/theming/_palette.scss */
$md-crc-red-palette: (
    50 : #fde5e4,
    100 : #f9bfbd,
    200 : #f69491,
    300 : #f26964,
    400 : #ef4843,
    500 : #ec2822,
    600 : #ea241e,
    700 : #e71e19,
    800 : #e41814,
    900 : #df0f0c,
    A100 : #ffffff,
    A200 : #ffd7d6,
    A400 : #ffa4a3,
    A700 : #ff8b8a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);