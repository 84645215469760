@import "variables";

.exit-survey,
.assessment {

  .mat-toolbar,
  .mat-toolbar {
    background: $black100;
    color: $white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: white !important;
    border: 1px solid $grey300;
    border-radius: 8px;
    min-height: 44px;
    margin-bottom: 8px;
  }

  .mat-mdc-chip-option {
    height: auto;

    .mat-mdc-chip-action-label {
      padding: 6px 0;
      white-space: normal;
      user-select: none;
      text-overflow: initial;
      overflow: initial;
    }
  }

  .mat-mdc-standard-chip.mat-mdc-chip-selected,
  .mat-mdc-standard-chip.mat-mdc-chip-highlighted {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
  }
}

.mat-input-limit-height {
  .mat-mdc-text-field-wrapper {
    height: 48px!important;
    .mat-mdc-floating-label {
      top: 24px !important;
    }
  }

  .mat-mdc-form-field-infix {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    min-height: 48px!important;
    max-height: 48px!important;
  }
}

.mdc-button.mat-primary {
  height: 44px;
  line-height: 44px;
  border-radius: 8px;
}

.mdc-dialog {
  .mdc-button {
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
  }
}

.icon-button.mat-mdc-icon-button {
  height: 25px;
  width: 25px;
  line-height: 25px;
  padding: 0;

  .mat-mdc-button-touch-target {
    height: 25px;
    width: 25px;
  }

  img {
    width: 18px;
    height: 18px;
  }
}



.mat-mdc-dialog-surface {
  padding: 20px;
}


.snackbar-success {
  background-color: $black100 !important;
  color: $white !important;
  min-width: unset;
  z-index: 7000;
  max-width: 80vw;
  @include media-breakpoint-down(md) {
    max-width: 95vw;
  }
}

.mat-drawer-container {
  background-color: $blue50 !important;
}


.edit-survey-dialog {
  .mat-mdc-dialog-surface {
    padding: 20px 10px 15px 10px;
  }
}

$input-border-color: $dark10;

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $input-border-color !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: $input-border-color !important;
}


app-hr-dashboard,
app-exit-survey-more-filter-popup-graph,app-exit-survey-more-filter-popup,
app-auth {

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px!important;
    padding-bottom: 12px!important;
  }

  .mat-mdc-form-field-infix {
    min-height: 48px!important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 24px!important;
  }
  .mat-mdc-text-field-wrapper {
    height: 48px;
  }
}

.cdk-overlay-pane {
  position: absolute!important;
}
.mat-mdc-snack-bar-label div:first-child {
  width: 100%;
}

.mat-mdc-form-field {
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 8px;
  }
}

.mat-mdc-form-field {
  .mat-icon {
    color: var(--mat-select-enabled-arrow-color);
  }
}

.employee-table {
  .action-button:focus, .action-button:active {
    .mat-mdc-button-persistent-ripple:before {
      outline: none !important;
      background-color: transparent !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .mat-mdc-slider .mdc-slider__thumb-knob {
    width: var(--mdc-slider-handle-width, 32px)!important;
    height: var(--mdc-slider-handle-height, 32px)!important;
    border-width: calc(var(--mdc-slider-handle-height, 32px) / 2) calc(var(--mdc-slider-handle-width, 32px) / 2);
  }
}
.mat-mdc-slider .mdc-slider__value-indicator {
  color: inherit!important;
}

.mat-mdc-slider .mdc-slider__value-indicator {
  background-color: transparent!important;
}

.error {
  margin: -10px 5px 10px 0;
  padding-bottom: 30px;
  font-size: 0.8em;
  color: var(--color-warn);
  position: absolute;
}

.error-outline, .is-invalid {
  border-color: var(--color-warn) !important;
  .mat-mdc-radio-button .mdc-radio .mdc-radio__outer-circle{
    border-color: var(--color-warn) !important;
  }
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background{
    border-color: var(--color-warn) !important;
  }
}

.tooltip-survey-table .mdc-tooltip__surface-animation {
  max-width: 220px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.exit-survey,
.assessment {
  .mat-mdc-chip-focus-overlay {
    background: unset;
  }
}


.checkbox-position-top .mdc-checkbox {
  align-self: flex-start;
}