@font-face {
  font-family: "LINESeedSans";
  src: url(/assets/fonts/LINESeedSansTH_W_Th.woff);
  font-weight: 100;
}

@font-face {
  font-family: "LINESeedSans";
  src: url(/assets/fonts/LINESeedSansTH_W_Rg.woff);
  font-weight: 500;
}

@font-face {
  font-family: "LINESeedSans";
  src: url(/assets/fonts/LINESeedSansTH_W_Bd.woff);
  font-weight: 700;
}

@font-face {
  font-family: "LINESeedSans";
  src: url(/assets/fonts/LINESeedSansTH_W_XBd.woff);
  font-weight: 800;
}

@font-face {
  font-family: "LINESeedSans";
  src: url(/assets/fonts/LINESeedSansTH_W_He.woff);
  font-weight: 900;
}