// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "variables";
@import "fonts-thai";
@import "ngx-charts";
@import "palettes/crc-red-palette";
@import "palettes/crc-black-palette";
@import 'bootstrap/scss/bootstrap';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$md-crc-red-palette: mat.$md-crcredpalette;
$crc-recruit-app-primary: mat.define-palette($md-crc-red-palette);
$crc-recruit-app-accent: mat.define-palette($md-crc-black-palette);

// The warn palette is optional (defaults to red).
$crc-recruit-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$crc-typography: mat.define-typography-config($font-family: "LINESeedSans",
    $headline-4: mat.define-typography-level(32px, 1.5, 700),
    $headline-5: mat.define-typography-level(24px, 1.5, 700),
    $headline-6: mat.define-typography-level(20px, 1.5, 700),
    $subtitle-1: mat.define-typography-level(18px, 1.5, 700),
    $subtitle-2: mat.define-typography-level(16px, 1.5, 700),
    $body-1: mat.define-typography-level(16px, 1.5, 500),
    $body-2: mat.define-typography-level(14px, 1.4, 500),
    $caption: mat.define-typography-level(12px, 1.32, 500),
    $button: mat.define-typography-level(16px, 44px, 700));

$crc-recruit-app-theme: mat.define-light-theme((
  color: (primary: $crc-recruit-app-primary,
        accent: $crc-recruit-app-accent,
        warn: $crc-recruit-app-warn,
      ),
      typography: $crc-typography,
    ));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($crc-recruit-app-theme);
@include mat.typography-hierarchy($crc-typography);

/* You can add global styles to this file, and also import other style files */


@import 'material.custom';
@import '_utility';


.font-responsive{
  @include media-breakpoint-down(md) {
    $crc-typography2: mat.define-typography-config($font-family: "LINESeedSans",
    $headline-4: mat.define-typography-level(30px, 1.5, 700),
    $headline-5: mat.define-typography-level(22px, 1.5, 700),
    $headline-6: mat.define-typography-level(18px, 1.5, 700),
    $subtitle-1: mat.define-typography-level(16px, 1.5, 700),
    $subtitle-2: mat.define-typography-level(14px, 1.5, 700),
    $body-1: mat.define-typography-level(14px, 1.5, 500),
    $body-2: mat.define-typography-level(12px, 1.4, 500),
    $caption: mat.define-typography-level(10px, 1.32, 500),
    $button: mat.define-typography-level(14px, 44px, 700));
    @include mat.typography-hierarchy($crc-typography2);
  }
}

:root {
  --color-primary: #{mat.get-color-from-palette($md-crc-red-palette, 500)};
  --color-accent: #{mat.get-color-from-palette($md-crc-black-palette, 500)};
  --color-warn: #{mat.get-color-from-palette(mat.$red-palette, 500)};
}

html {
  background-color: inherit;
}

html,
body {
  height: 100%;
  font-family: 'LINESeedSans';

  .default {
    background-color: $blue50;
  }
  .white {
    background-color: $white;
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button,
a {
  &.mat-raised-button,
  &.mat-button,
  &.mat-flat-button,
  &.mat-stroked-button {
    border-radius: 26px;
  }
  &.mat-mdc-outlined-button:not([disabled]) {
    border-color: $grey300;

    &.mat-primary {
      border-color: mat.get-color-from-palette($crc-recruit-app-primary, 500);
    }

    &.mat-warn {
      border-color: mat.get-color-from-palette($crc-recruit-app-warn, 500);
    }

    &.mat-accent {
      border-color: mat.get-color-from-palette($crc-recruit-app-accent, 500);
    }

  }

}