$blue50: #F1F6FE;
$black100: #070707;
$white: #FFFFFF;
$active-link: #EDEDED;
$dark100: #3D4655;
$dark50: #555E6D;
$centralRed: #EC2822;
$blue100: #F4F8FF;
$blue90: #CDE5FF;
$blue80: #DEEAFF;
$dark10: #6C7686;
$grey300: #EBEBEB;
$blue: #368EFF;
$green: #34B53A;
$green20: green;
$red: #FA0505;
$orange: #F2994A;